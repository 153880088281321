// Generated by Framer (8d84d1c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Iconoir } from "https://framerusercontent.com/modules/zL9598C4KbEbqUGvSR14/rI8sPHpnG9XGcCPc0vU4/Iconoir.js";
const IconoirFonts = getFonts(Iconoir);

const enabledGestures = {HmdUkds_S: {hover: true}};

const cycleOrder = ["PeOC2QMLY", "HmdUkds_S"];

const serializationHash = "framer-LCvO8"

const variantClassNames = {HmdUkds_S: "framer-v-bs3nl3", PeOC2QMLY: "framer-v-qanlbm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 0.1, ease: [0.4, 0, 0.2, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Close with hover": "HmdUkds_S", Close: "PeOC2QMLY"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, iCHBmJUKg: click ?? props.iCHBmJUKg, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "PeOC2QMLY"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, iCHBmJUKg, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PeOC2QMLY", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapv5fdsp = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (iCHBmJUKg) {const res = await iCHBmJUKg(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({HmdUkds_S: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-qanlbm", className, classNames)} data-framer-name={"Close"} data-highlight layoutDependency={layoutDependency} layoutId={"PeOC2QMLY"} onTap={onTapv5fdsp} ref={ref ?? ref1} style={{"--border-bottom-width": "0px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backdropFilter: "none", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0, WebkitBackdropFilter: "none", ...style}} variants={{"HmdUkds_S-hover": {"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.1)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px"}, HmdUkds_S: {backdropFilter: "blur(10px)", backgroundColor: "rgba(0, 0, 0, 0.2)", borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, WebkitBackdropFilter: "blur(10px)"}}} {...addPropertyOverrides({"HmdUkds_S-hover": {"data-border": true, "data-framer-name": undefined}, HmdUkds_S: {"data-framer-name": "Close with hover"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-mvf7d0-container"} layoutDependency={layoutDependency} layoutId={"gUTg201HV-container"}><Iconoir color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"mark"} iconSelection={"Cancel"} id={"gUTg201HV"} layoutId={"gUTg201HV"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LCvO8.framer-1478nu2, .framer-LCvO8 .framer-1478nu2 { display: block; }", ".framer-LCvO8.framer-qanlbm { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-LCvO8 .framer-mvf7d0-container { flex: none; height: 35px; position: relative; width: 35px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-LCvO8.framer-qanlbm { gap: 0px; } .framer-LCvO8.framer-qanlbm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-LCvO8.framer-qanlbm > :first-child { margin-left: 0px; } .framer-LCvO8.framer-qanlbm > :last-child { margin-right: 0px; } }", ".framer-LCvO8.framer-v-bs3nl3.framer-qanlbm { padding: 2px; }", ".framer-LCvO8.framer-v-bs3nl3 .framer-mvf7d0-container { height: 50px; width: 50px; }", ".framer-LCvO8.framer-v-bs3nl3.hover .framer-mvf7d0-container { height: 45px; width: 45px; }", ".framer-LCvO8[data-border=\"true\"]::after, .framer-LCvO8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 35
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"HmdUkds_S":{"layout":["auto","auto"]},"ZfPQHogln":{"layout":["auto","auto"]}}}
 * @framerVariables {"iCHBmJUKg":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerxfgkFgAUy: React.ComponentType<Props> = withCSS(Component, css, "framer-LCvO8") as typeof Component;
export default FramerxfgkFgAUy;

FramerxfgkFgAUy.displayName = "Buttons / Close Button";

FramerxfgkFgAUy.defaultProps = {height: 35, width: 35};

addPropertyControls(FramerxfgkFgAUy, {variant: {options: ["PeOC2QMLY", "HmdUkds_S"], optionTitles: ["Close", "Close with hover"], title: "Variant", type: ControlType.Enum}, iCHBmJUKg: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerxfgkFgAUy, [{explicitInter: true, fonts: []}, ...IconoirFonts], {supportsExplicitInterCodegen: true})